<script setup lang="ts">
import StickyFooterBtn from "~/components/StickyFooterBtn.vue";

interface Props {
  buttonDisabled?: boolean;
  hasWideWrapper?: boolean;
  isLoading?: boolean;
  buttonText: string;
}

const props = defineProps<Props>();

const showAdditionalButton = ref(false);
const additionalButtonWrapper = ref<HTMLDivElement | null>(null);
const additionalButton = ref<HTMLButtonElement | null>(null);

interface Emits {
  (e: "buttonClick"): void;
}

const emit = defineEmits<Emits>();

function onVisualViewportResize() {
  setTimeout(() => {
    showAdditionalButton.value = (window.visualViewport?.height ?? 550) < 550;

    if (showAdditionalButton.value) {
      additionalButtonWrapper.value?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, 300);
}

function onAdditionalButtonClick() {
  if (props.buttonDisabled || !showAdditionalButton.value) {
    return;
  }

  emit("buttonClick");
}

onMounted(() => {
  if (
    navigator.userAgent.includes("Mobile") &&
    !navigator.userAgent.includes("Instagram") &&
    !navigator.userAgent.includes("Firefox")
  ) {
    window.visualViewport?.addEventListener("resize", onVisualViewportResize);

    setTimeout(() => {
      onVisualViewportResize();
    }, 500);
  }
});
</script>

<template>
  <div
    class="!tw-flex !tw-flex-col !tw-justify-between md:!tw-justify-start"
    :class="[props.hasWideWrapper ? 'onboarding-content' : 'onboarding-content-progress']"
  >
    <div ref="additionalButtonWrapper">
      <slot />

      <div class="tw-flex tw-justify-center">
        <div
          ref="additionalButton"
          class="bg-gradient-primary tw-text-md tw-m-auto tw-min-w-80 tw-rounded-3xl tw-border-0 tw-px-8 tw-py-3 tw-text-center tw-font-medium tw-text-white tw-transition-opacity tw-duration-300"
          :class="{ 'tw-cursor-pointer tw-opacity-100': showAdditionalButton, 'tw-opacity-0': !showAdditionalButton }"
          @click="onAdditionalButtonClick"
        >
          <span>{{ buttonText }}</span>
        </div>
      </div>
    </div>

    <StickyFooterBtn
      class="container py-3 tw-transition-opacity tw-duration-300"
      :class="{
        'tw-opacity-0': showAdditionalButton,
        'tw-opacity-100': !showAdditionalButton,
      }"
      :disabled="buttonDisabled"
      col-classes="col-lg-7"
      @submit="$emit('buttonClick')"
    >
      <span v-if="isLoading" class="spinner-border spinner-border-sm" aria-hidden="true" />
      {{ buttonText }}
    </StickyFooterBtn>
  </div>
</template>
