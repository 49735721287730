import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import type { Voucher } from "~/types/voucher";

export const useVoucherStore = defineStore(
  "voucher",
  () => {
    const backendApiClient = useBackendApiAppDomainClient();

    function getValidVoucherCodeDetails(code: string): Promise<Voucher> {
      return backendApiClient.getValidVoucherCodeDetails(code);
    }

    function redeemCouponCode(code: string): Promise<void> {
      return backendApiClient.redeemCouponCode(code);
    }

    return {
      getValidVoucherCodeDetails,
      redeemCouponCode,
    };
  },
  {
    persist: {
      storage: persistedState.cookiesWithOptions({
        maxAge: DaysToSeconds(1),
      }),
    },
  },
);
